const DEFAULT_LAT_LON = [46.9255488, 3.081462];
const DEFAULT_ZOOM = 6;

const MAPPING = {
    "Europe/Paris": {
        center: [46.9255488, 3.081462],
        zoom: 6
    },
    "Europe/London": {
        center: [51.5287393, -0.2667475],
        zoom: 6
    },
    "Europe/Dublin": {
        center: [53.3244112, -6.4108525],
        zoom: 6
    },
    "Europe/Berlin": {
        center: [52.5069381, 13.2595832],
        zoom: 6
    },
    "Europe/Brussels": {
        center: [50.8552019, 4.2928447],
        zoom: 6
    },
    "Europe/Luxembourg": {
        center: [49.6076118, 5.9801555],
        zoom: 8
    },
    "Europe/Zurich": {
        center: [47.3775283, 8.4541635],
        zoom: 6
    },
    "America/Guadeloupe": {
        center: [16.1500349, -61.7273907],
        zoom: 8
    },
    "America/Guyana": {
        center: [4.9350862,-58.1295401],
        zoom: 6
    },
    "America/Cayenne": {
        center: [5.0924066, -52.5437785],
        zoom: 6
    },
    "America/Martinique": {
        center: [14.6339258, -61.6369911],
        zoom: 8
    },
    "America/Montreal": {
        center: [45.5593041, -73.8770234],
        zoom: 6
    },
    "America/Port-au-Prince": {
        center: [18.5791359, -72.4401827],
        zoom: 8
    },
    "America/Miquelon": {
        center: [46.9580437, -56.5832221],
        zoom: 8
    },
    "Indian/Mauritius": {
        center: [-15.3521956, 53.7130107],
        zoom: 8
    },
    "Indian/Mayotte": {
        center: [-12.8061636, 44.9906288],
        zoom: 8
    },
    "Indian/Reunion": {
        center: [-21.1343817, 55.1969695],
        zoom: 8
    },
    "Pacific/Noumea": {
        center: [-22.2642727, 166.4035813],
        zoom: 8
    },
    "Pacific/Tahiti": {
        center: [-17.6868736,-149.5379579],
        zoom: 8
    }
};

const getCountryCenterFromTZ = (tz) => {
    if(Object.keys(MAPPING).includes(tz)) {
        return MAPPING[tz].center;
    } else {
        return DEFAULT_LAT_LON;
    }
};

const getCountryZoomFromTZ = (tz) => {
    if(Object.keys(MAPPING).includes(tz)) {
        return MAPPING[tz].zoom;
    } else {
        return DEFAULT_ZOOM;
    }
};

export {getCountryCenterFromTZ, getCountryZoomFromTZ};