import React from 'react';
//import './Movings.css';
import io from './utils/io';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Divider,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Message,
  Modal,
  Pagination,
  Table,
  Segment
} from "semantic-ui-react";
import {
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import Moment from './utils/momentfr';
import { extendMoment } from 'moment-range';
import { Circle, FeatureGroup, Map as LeafletMap, Marker, TileLayer, Polyline, Tooltip } from 'react-leaflet';
//import PixiOverlay from "react-leaflet-pixi-overlay";
import PixiOverlay from "./utils/PixiOverlay";
import {geocodeAddr, reverseGeocodeAddrStr} from './utils/photon';
import {getContrast, getEmployeeColor, getMarkerIconForEmployee} from './utils/map';
import { isSubmoduleVisible, timezone } from './utils/localSettings';
import groupBy from './utils/groupBy';
import { getCountryCenterFromTZ, getCountryZoomFromTZ } from './utils/countryCoordinates';

const moment = extendMoment(Moment());

const POINTAGES_PER_PAGE = 20;
const TRIPS_PER_PAGE = 20;
const LOCATIONS_PER_PAGE = 10;

class Movings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      teams: [],
      locations: [],
      summary: [],
      traccarSummary: [],
      carsPoints: [],
      cars: [],
      employees: [],
      carEmployees: [],
      mapRoutes: [],
      selectedEmployeeFilter: [],
      selectedDateFilter: {
        from: moment(),
        to: moment(),
        readable: ""
      },
      carFilters: {
        selectedCarFilter: "ALL",
        selectedCarId: -1,
        selectedDateFilter: {
          from: moment(),
          to: moment(),
          readable: ""
        }
      },
      selectedTeamFilter: "ALL",
      selectedTeamId: -1,
      map_latLon: getCountryCenterFromTZ(timezone()),
      map_zoom: getCountryZoomFromTZ(timezone()),
      car_map_latLon: getCountryCenterFromTZ(timezone()),
      car_map_zoom: getCountryZoomFromTZ(timezone()),
      // Reverse geocoding
      addrForTrips: {},
      addrForPointages: {},
      loadingPhones: false,
      currentPhonePage: 0,
      loadingCars: false,
      currentCarPage: 0,
      phoneMapHasFocus: false,
      carMapHasFocus: false,

      editingLocation: {},
      // The next is for modal
      isLocationSelectModalOpened: false,
      searchingAddrs: false,
      foundAddr: [],
      modalMap_latLon: getCountryCenterFromTZ(timezone()),
      modalMap_zoom: getCountryZoomFromTZ(timezone()),
      selectedLatLng: [],
      // Modal
      deleteLocationModal: false,
      locationIdToDelete: undefined,
      addrForLocations: {},
      error: "",

      currentLocationPage: 0,

      sortState: {
        employees: {},
        cars: {}
      },

      accordionLocationsOpen: false,
      loadingGeocodingForPhones: [],
      loadingGeocodingForTrips: []
    };
    this.state = Object.assign(this.state, {
      listeners: {
        GET_SUMMARY: this.onSummaryInit.bind(this),
        GET_TRIPS: this.onTraccarSummaryInit.bind(this),
        GET_LOCATION: this.onLocationsInit.bind(this),
        ADD_LOCATION: this.onLocationAddResponse.bind(this),
        UPD_LOCATION: this.onLocationUpdateResponse.bind(this),
        DEL_LOCATION: this.onLocationDeleteResponse.bind(this),
        GET_CARS: this.onCarsInit.bind(this),
        GET_CARS_POINTS: this.onCarsPointsInit.bind(this),
        GET_CAREMPLOYEE: this.onCarEmployeesInit.bind(this),
        GET_EMPLOYEE: this.onEmployeesInit.bind(this),
        GET_TEAM: this.onTeamsInit.bind(this)
      }
    });
    this.handleEscape = this.handleEscape.bind(this);
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    this.state.socket.emit('GET_LOCATION');
    this.state.socket.emit('GET_EMPLOYEE');
    this.state.socket.emit('GET_TEAM');
    this.state.socket.emit('GET_CARS');
    this.state.socket.emit('GET_CAREMPLOYEE');
    this.initDateFilter();
    this.initCarDateFilter();

    // For escape key
    document.addEventListener("keydown", this.handleEscape, false);
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));

    // For escape key
    document.removeEventListener("keydown", this.handleEscape, false);
  }

  handleEscape(event) {
    if(event.keyCode === 27) {
      this.setState({
        editingLocation: {}
      });
    }
  }

  resetMap() {
    this.setState({
      map_latLon: getCountryCenterFromTZ(timezone()),
      map_zoom: getCountryZoomFromTZ(timezone())
    });
  }

  initDateFilter() {
    this.setState({
      //byDate: false,
      selectedDateFilter: {
        from: moment().subtract(1, 'd').hours(0).minutes(0).seconds(0),
        to: moment().subtract(1, 'd').hours(23).minutes(59).seconds(59),
        readable: ""
      }
    }/*, this.refreshSummary*/);
  }

  initCarDateFilter() {
    this.setState({
      carFilters: Object.assign(this.state.carFilters, {
        selectedDateFilter: {
          from: moment().subtract(1, 'd').hours(0).minutes(0).seconds(0),
          to: moment().subtract(1, 'd').hours(23).minutes(59).seconds(59),
          readable: ""
        }
      })
    }/*, this.refreshTraccarSummary*/);
  }

  onCarsInit(cars) {
    this.setState({
      cars
    });
  }

  onEmployeesInit(employees) {
    this.setState({
      employees
    });
  }

  onTeamsInit(teams) {
    this.setState({
      teams
    });
  }

  onChangeDateFilter(e,data) {
    // TODO WTF ??
    const parts = data.value.split(' - ');
    let [from, to] = parts;

    if(from === "") {
      from = undefined;
    }
    if(to === "") {
      to = undefined;
    }

    if(from === undefined && to === undefined) {
      this.initDateFilter();
    } else {
      const readable = data.value;
      this.setState({
        //byDate: false,
        selectedDateFilter: {
          from: from ? moment(from, "DD/MM/YYYY") : from,
          to: to ? moment(to, "DD/MM/YYYY").hour(23).minute(59).second(59) : to,
          readable
        }
      }/*, this.refreshSummary*/);
    }
  }

  onChangeCarDateFilter(e,data) {
    // TODO WTF ??
    const parts = data.value.split(' - ');
    let [from, to] = parts;

    if(from === "") {
      from = undefined;
    }
    if(to === "") {
      to = undefined;
    }

    if(from === undefined && to === undefined) {
      this.initCarDateFilter();
    } else {
      const readable = data.value;
      this.setState({
        carFilters: Object.assign(this.state.carFilters, {
          selectedDateFilter: {
            from: from ? moment(from, "DD/MM/YYYY") : from,
            to: to ? moment(to, "DD/MM/YYYY").hour(23).minute(59).second(59) : to,
            readable
          }
        })
      }/*, this.refreshTraccarSummary*/);
    }
  }

  onChangeTeamFilter(e,data) {
    this.setState({
      selectedTeamFilter: data.value,
      selectedTeamId: data.value === "ALL" ? this.state.teams.length > 0 ? this.state.teams[0].id : -1 : data.value,
      selectedEmployeeFilter: []
    });
  }

  reverseGeocodingForPhones(pointages, currentPhonePage) {
    // Reverse geocoding
    const filteredPointages = pointages.filter(p => this.state.addrForPointages[p.id] === undefined && this.state.loadingGeocodingForPhones.includes(p.id) === false);
    const pointagesIds = filteredPointages.map(p => p.id);
    this.setState({
      loadingGeocodingForPhones: [...this.state.loadingGeocodingForPhones.filter(x => pointagesIds.includes(x) === false), ...pointagesIds]
    }, () => {
      return pointages.reduce((acc, p) => {
        return acc.then(() => {
            if(p.gpsCoords !== '' && p.gpsCoords !== undefined && p.gpsCoords !== null) {
              const latlon = p.gpsCoords.split('|');
              return reverseGeocodeAddrStr(latlon[0], latlon[1]).then(addr => {
                return this.setState({
                  addrForPointages: Object.assign(this.state.addrForPointages, {
                    [p.id]: addr
                  }),
                  loadingGeocodingForPhones: this.state.loadingGeocodingForPhones.filter(x => x !== p.id)
                });
              });
            } else {
              return this.setState({
                addrForPointages: Object.assign(this.state.addrForPointages, {
                  [p.id]: ' '
                }),
                loadingGeocodingForPhones: this.state.loadingGeocodingForPhones.filter(x => x !== p.id)
              });
            }
        });
      }, Promise.resolve());
    });
  }

  onPhonePageChange(evt, d) {
    this.setState({
      currentPhonePage: d.activePage !== undefined && d.activePage !== null ? d.activePage-1 : 0
    }, () => {
      this.reverseGeocodingForPhones(this.sortEmployees().slice(this.state.currentPhonePage * POINTAGES_PER_PAGE, this.state.currentPhonePage * POINTAGES_PER_PAGE + POINTAGES_PER_PAGE), this.state.currentPhonePage);
    });
  }

  onSummaryInit({_reqID, summary}) {
    this.setState({
      summary: summary.filter(x => this.state.selectedTeamFilter !== "ALL" ? x.teamId === this.state.selectedTeamId : true),
      loadingPhones: false,
      currentPhonePage: 0,
      addrForPointages: {}
    }, () => {
      this.onPhonePageChange(null, {activePage: this.state.currentPhonePage+1});
    });
  }

  onCarEmployeesInit(carEmployees) {
    this.setState({
      carEmployees
    });
  }

  getTripId(trip) {
    return `${trip.deviceId}_${trip.startPositionId}_${moment(trip.startTime).unix()}`;
  }

  reverseGeocodingForCars(trips, currentCarPage) {
    // Reverse geocoding
    const filteredTrips = trips.filter(t => this.state.addrForTrips[this.getTripId(t)] === undefined && this.state.loadingGeocodingForTrips.includes(this.getTripId(t)) === false);
    const tripsIds = filteredTrips.map(t => this.getTripId(t));
    this.setState({
      loadingGeocodingForTrips: [...this.state.loadingGeocodingForTrips.filter(x => tripsIds.includes(x) === false), ...tripsIds]
    }, () => {
      return filteredTrips.reduce((acc, trip, idx) => {
        // TODO that if only check the first element of the page
        const tripId = this.getTripId(trip);
        return acc.then(() => Promise.all([reverseGeocodeAddrStr(trip.startLat, trip.startLon), reverseGeocodeAddrStr(trip.endLat, trip.endLon)]))
        .then(([startAddr, endAddr]) => {
          this.setState({
            addrForTrips: Object.assign(this.state.addrForTrips, {
              [tripId]: {
                start: startAddr,
                end: endAddr
              }
            }),
            loadingGeocodingForTrips: this.state.loadingGeocodingForTrips.filter(x => x !== tripId)
          });
        }).catch((e) => {
          this.setState({
            addrForTrips: Object.assign(this.state.addrForTrips, {
              [tripId]: {
                start: ' ',
                end: ' '
              }
            }),
            loadingGeocodingForTrips: this.state.loadingGeocodingForTrips.filter(x => x !== tripId)
          });
        });
      }, Promise.resolve());
    });
  }

  onTripPageChange(evt, d) {
    this.setState({
      currentCarPage: d.activePage !== undefined && d.activePage !== null ? d.activePage-1 : 0
    }, () => {
      this.reverseGeocodingForCars(this.sortTraccarSummary().slice(this.state.currentCarPage * TRIPS_PER_PAGE, this.state.currentCarPage * TRIPS_PER_PAGE + TRIPS_PER_PAGE), this.state.currentCarPage);
    });
  }

  onTraccarSummaryInit({_reqID, summary}) {
    this.setState({
      traccarSummary: summary.filter((trip) => this.state.carFilters.selectedCarId === -1 || this.state.carFilters.selectedCarId === trip.deviceId),
      loadingCars: false,
      currentCarPage: 0,
      addrForTrips: {}
    }, () => {
      this.onTripPageChange(null, {activePage: this.state.currentCarPage+1});
    });
  }

  onCarsPointsInit(points) {
    this.setState({
      carsPoints: points.filter((p) => this.state.carFilters.selectedCarId === -1 || this.state.carFilters.selectedCarId === p.deviceId),
    });
  }

  onLocationsInit(locations) {
    this.setState({
      locations: locations.sort((l1, l2) => l1.name < l2.name ? -1 : 1)
    }, () => {
      this.onLocationPageChange(null, {activePage: this.state.currentLocationPage+1});
    });
  }

  onLocationPageChange(evt, d) {
    this.setState({
      currentLocationPage: d.activePage !== undefined && d.activePage !== null ? d.activePage-1 : 0
    }, () => {
      this.reverseGeocodingForLocations(this.state.locations.slice(this.state.currentLocationPage * LOCATIONS_PER_PAGE, this.state.currentLocationPage * LOCATIONS_PER_PAGE + LOCATIONS_PER_PAGE), this.state.currentLocationPage);
    });
  }

  reverseGeocodingForLocations(locations, currentLocationPage) {
    // Reverse geocoding
    return locations.reduce((acc, l) => {
      if(this.state.addrForLocations[l.id] === undefined) {
        return acc.then(() => {
            if(l.gpsCoords !== '' && l.gpsCoords !== undefined && l.gpsCoords !== null) {
              const latlon = l.gpsCoords.split('|');
              return reverseGeocodeAddrStr(latlon[0], latlon[1]).then(addr => {
                return this.setState({
                  addrForLocations: Object.assign(this.state.addrForLocations, {
                    [l.id]: addr
                  })
                });
              });
            } else {
              return this.setState({
                addrForLocations: Object.assign(this.state.addrForLocations, {
                  [l.id]: ' '
                })
              });
            }
        });
      } else {
        return Promise.resolve();
      }
    }, Promise.resolve());
  }

  manageErrors(data, stateErr = 'error') {
    if(data.err !== undefined) {
      this.setState({
        [stateErr]: data.err
      });
      setTimeout(() => {
        this.setState({
          [stateErr]: ""
        });
      }, 5000);
    }
  }

  manageEnterKey(event) {
    if(event.key === 'Enter') {
      if(Object.keys(this.state.editingLocation).length > 0) {
        this.onClickOnUpdLocation()(event);
      }
    }
  }

  onLocationAdd(location) {
    this.state.socket.emit('ADD_LOCATION', location);
  }

  onLocationAddResponse(location) {
    this.manageErrors(location);
    this.setState({
      editingLocation: Object.assign({}, location, {
        tmpAddr: ' '
      }),
      addrForLocations: Object.assign({}, this.state.addrForLocations, {
        [location.id]: undefined
      })
    });
    this.state.socket.emit('GET_LOCATION');
  }

  onLocationUpdate(location) {
    this.state.socket.emit('UPD_LOCATION', location);
  }

  onLocationUpdateResponse(location) {
    this.setState({
      editingLocation: {},
      addrForLocations: Object.assign({}, this.state.addrForLocations, {
        [location.id]: undefined
      })
    });
    this.state.socket.emit('GET_LOCATION');
    this.manageErrors(location);
  }

  onLocationDelete(location) {
    this.state.socket.emit('DEL_LOCATION', {id: location.id});
  }

  onLocationDeleteResponse(location) {
    this.manageErrors(location);
    this.state.socket.emit('GET_LOCATION');
  }

  onClickOnAddLocation(event) {
    const locationRegexp = /^- Nouveau site \((\d{1,})\)$/g;
    const existingLocationNumbers = this.state.locations.map(l => Array.from(l.name.matchAll(locationRegexp))).filter(matches => matches.length > 0 && matches[0].length >= 2).map(matches => parseInt(matches[0][1]));
    const highestNumber = [...existingLocationNumbers].sort((x, y) => x < y ? 1 : -1)[0] || 0;
    this.onLocationAdd({
      name: `- Nouveau site${` (${highestNumber+1})`}`
    });
  }

  onClickOnLocationRow(location) {
    return (event) => {
      if(location.id !== this.state.editingLocation.id) {
        this.setState({
          editingLocation: Object.assign({}, location, {
            tmpAddr: this.state.addrForLocations[location.id]
          })
        });
      }
    };
  }

  onClickOnUpdLocation() {
    return (event) => {
      this.onLocationUpdate(this.state.editingLocation);
      this.setState({
        editingLocation: {}
      });
    };
  }

  onClickOnDelLocation(event) {
    this.onLocationDelete({
      id: this.state.locationIdToDelete
    });
    this.toggleDeleteLocationModal();
  }

  onChangeLocationProp(propName, fromMinimap = false) {
    return (event) => {
      if(fromMinimap === false) {
        event.persist();
      }
      const caretStart = event.target.selectionStart;
      const caretEnd = event.target.selectionEnd;
      let realPropName = propName;
      let data = event.target.value;
      if(propName === "gpsCoords_lat") {
        const lat = parseFloat(data);
        const lon = this.state.editingLocation.gpsCoords ? this.state.editingLocation.gpsCoords.split("|")[1] : "000.000000";
        realPropName = "gpsCoords";
        data = (isNaN(lat) ? "00.000000" : lat.toFixed(6).toString()) + "|" + lon.toString();
      } else if(propName === "gpsCoords_lon") {
        const lat = this.state.editingLocation.gpsCoords ? this.state.editingLocation.gpsCoords.split("|")[0] : "00.000000";
        const lon = parseFloat(data);
        realPropName = "gpsCoords";
        data = lat.toString() + "|" + (isNaN(lon) ? "000.000000" : lon.toFixed(6).toString());
      }
      this.setState({
        editingLocation: Object.assign({}, this.state.editingLocation, {[realPropName]: data})
      }, () => {
        if(fromMinimap === false) {
          event.target.setSelectionRange(caretStart, caretEnd);
        }
      });
    };
  }

  toggleLocationSelectModal(l) {
    return (evt) => {
      this.setState({
        isLocationSelectModalOpened: !this.state.isLocationSelectModalOpened
      });
    };
  }

  onChangeSearchAddrModal(e, data) {
    const searchPattern = data.searchQuery;
    if(this.state.searchingAddrs === false && searchPattern !== null && searchPattern !== undefined && searchPattern.length >= 4) {
      this.setState({
        searchingAddrs: true
      }, () => {
        geocodeAddr(searchPattern).then((data) => {
          this.setState({
            foundAddr: data
          });
        }).finally(() => {
          this.setState({searchingAddrs: false});
        });
      });
    }
  }

  onChangeAddr(e, data) {
    const latLon = data.value.split('|');
    this.setState({
      modalMap_latLon: latLon,
      selectedLatLng: latLon,
      modalMap_zoom: 17
    });
  }

  onClickOnModalMap(e, data) {
    const latlng = [e.latlng.lat, e.latlng.lng];
    this.setState({
      selectedLatLng: latlng
    });
  }

  resetModal() {
    this.setState({
      isLocationSelectModalOpened: false,
      modalMap_latLon: getCountryCenterFromTZ(timezone()),
      modalMap_zoom: getCountryZoomFromTZ(timezone()),
      searchingAddrs: false,
      foundAddr: [],
      selectedLatLng: []
    });
  }

  onClickOnModalValidate(evt) {
    this.onChangeLocationProp('gpsCoords', true)({
      target: {value: this.state.selectedLatLng.join('|')}
    });
    this.resetModal();
    reverseGeocodeAddrStr(this.state.selectedLatLng[0], this.state.selectedLatLng[1]).then((addrStr) => {
      this.setState({
        editingLocation: Object.assign({}, this.state.editingLocation, {
          tmpAddr: addrStr
        })
      });
    });
  }

  onClickOnModalCancel(e) {
    this.resetModal();
  }

  toggleDeleteLocationModal(event) {
    this.setState({
      deleteLocationModal: !this.state.deleteLocationModal
    });
  }

  onDeleteLocationModalOpen(id) {
    return (event) => {
      this.setState({
        locationIdToDelete: id
      });
    }
  }

  onChangeEmployeeFilter(e,data) {
    this.setState({
      //byDate: false,
      selectedEmployeeFilter: data.value
    }, this.refreshSummary);
  }

  onChangeCarFilter(e,data) {
    this.setState({
      carFilters: Object.assign(this.state.carFilters, {
        selectedCarFilter: data.value,
        selectedCarId: data.value === "ALL" ? -1 : data.value,
      })
    }/*, this.refreshTraccarSummary*/);
  }

  handleSort(submodule, clickedColumn) {
    return (evt) => {
      const { column, direction } = this.state.sortState[submodule];
      const nextDirection = direction === 'ascending' ? 'descending' : 'ascending';
      this.setState({
        sortState: Object.assign(this.state.sortState, {
          [submodule]: {
            column: clickedColumn,
            direction: nextDirection
          }
        })
      }, () => {
        if(submodule === 'employees') {
          this.reverseGeocodingForPhones(this.sortEmployees().slice(this.state.currentPhonePage * POINTAGES_PER_PAGE, this.state.currentPhonePage * POINTAGES_PER_PAGE + POINTAGES_PER_PAGE), this.state.currentPhonePage);
        } else if(submodule === 'cars') {
          this.reverseGeocodingForCars(this.sortTraccarSummary().slice(this.state.currentCarPage * TRIPS_PER_PAGE, this.state.currentCarPage * TRIPS_PER_PAGE + TRIPS_PER_PAGE), this.state.currentCarPage);
        }
      });
    }
  }

  refreshSummary() {
    if(this.state.selectedDateFilter.from !== undefined && this.state.selectedDateFilter.to !== undefined) {
      this.setState({
        loadingPhones: true
      }, () => {
        const summaryParams = {
          _reqID: "locations",
          from: this.state.selectedDateFilter.from.unix(),
          to: this.state.selectedDateFilter.to.unix(),
          employeeId: this.state.selectedEmployeeFilter.length === 0 ? undefined : this.state.selectedEmployeeFilter,
          onlyGPSCoords: true
        };
        this.state.socket.emit('GET_SUMMARY', summaryParams)
      });
    }
  }

  refreshTraccarSummary() {
    if(this.state.carFilters.selectedDateFilter.from !== undefined && this.state.carFilters.selectedDateFilter.to !== undefined) {
      this.setState({
        loadingCars: true
      }, () => {
        const summaryParams = {
          _reqID: "locationsTraccar",
          from: this.state.carFilters.selectedDateFilter.from.unix(),
          to: this.state.carFilters.selectedDateFilter.to.unix(),
          employeeId: undefined
        };
        this.state.socket.emit('GET_TRIPS', summaryParams)
        this.state.socket.emit('GET_CARS_POINTS', {from: summaryParams.from, to: summaryParams.to})
      });
    }
  }

  // See any point on the map directly
  handleGoTo([lat, lon]) {
    return (evt) => {
      this.setState({
        map_latLon: [lat, lon],
        map_zoom: 17
      });
    };
  }

  handleGoToCar([lat, lon]) {
    return (evt) => {
      this.setState({
        car_map_latLon: [lat, lon],
        car_map_zoom: 17
      });
    };
  }

  sortEmployees() {
    const sortedData = this.state.summary.map(e => e.pointages.filter(p => p.gpsCoords !== null && p.gpsCoords !== undefined).map(p => Object.assign({}, p, {employee: {id: e.id, name: e.name}}))).flat()
    .sort((x1, x2) => {
      switch(this.state.sortState.employees.column) {
        case "employeeName":
          return x1.employee.name < x2.employee.name ? -1 : 1;
        case "timestamp":
          return x1.timestamp < x2.timestamp ? -1 : 1;
        case "location":
          const x1Loc = x1.location !== "" && x1.location !== null && x1.location !== undefined ? x1.location : 'Inconnu';
          const x2Loc = x2.location !== "" && x2.location !== null && x2.location !== undefined ? x2.location : 'Inconnu';
          return x1Loc < x2Loc ? -1 : 1;
        default:
          return 0;
      }
    });
    return (this.state.sortState.employees.direction === 'descending' ? sortedData.reverse() : sortedData);
  }

  sortTraccarSummary() {
    const sortedSummary = [...this.state.traccarSummary].sort((x1, x2) => {
      const tripCar1 = this.state.cars.find(x => x.id === x1.deviceId);
      const carEmployee1 = tripCar1 !== undefined ? this.state.carEmployees.find(x => x.carImei === tripCar1.imei) : undefined;
      const employee1 = carEmployee1 !== undefined ? this.state.employees.find(x => x.id === carEmployee1.employeeId) : undefined;
      const tripCar2 = this.state.cars.find(x => x.id === x2.deviceId);
      const carEmployee2 = tripCar2 !== undefined ? this.state.carEmployees.find(x => x.carImei === tripCar2.imei) : undefined;
      const employee2 = carEmployee2 !== undefined ? this.state.employees.find(x => x.id === carEmployee2.employeeId) : undefined;
      
      switch(this.state.sortState.cars.column) {
        case "avgSpeed":
          return Math.round(x1.averageSpeed) < Math.round(x2.averageSpeed) ? -1 : 1;
        case "duration":
          return moment.duration(x1.duration).as('minutes') < moment.duration(x2.duration).as('minutes') ? -1 : 1;
        case "startTime":
          return x1.startTime < x2.startTime ? -1 : 1;
        case "endTime":
          return x1.endTime < x2.endTime ? -1 : 1;
        case "employee":
          if(employee1 === undefined) {
            return 1;
          } else if(employee2 === undefined) {
            return -1;
          } else {
            return employee1.name < employee2.name ? -1 : 1;
          }
        case "vehicleName":
          const v1Name = `${x1.deviceName}${carEmployee1 !== undefined ? ` ${carEmployee1.licensePlate || ""}` : ''}`;
          const v2Name = `${x2.deviceName}${carEmployee2 !== undefined ? ` ${carEmployee2.licensePlate || ""}` : ''}`;
          return v1Name < v2Name ? -1 : 1;
        default:
          return 0;
      }
    });

    return (this.state.sortState.cars.direction === 'descending' ? sortedSummary.reverse() : sortedSummary);
  }

  handleClickLocationAccordion(e) {
    this.setState({ accordionLocationsOpen: !this.state.accordionLocationsOpen });
  }

  render() {
    const editingGpsCoords = this.state.editingLocation.gpsCoords !== undefined && this.state.editingLocation.gpsCoords !== null && this.state.editingLocation.gpsCoords.indexOf("|") >= 0 ? this.state.editingLocation.gpsCoords : "00.000000|000.000000";
    const editingGpsSplit = editingGpsCoords.split('|');
    const editingLat = editingGpsSplit[0] !== undefined && editingGpsSplit[0] !== null && editingGpsSplit[0] !== "" ? editingGpsSplit[0] : "00.000000";
    const editingLon = editingGpsSplit[1] !== undefined && editingGpsSplit[1] !== null && editingGpsSplit[1] !== "" ? editingGpsSplit[1] : "000.000000";

    const locationsHTML = this.state.locations.slice(this.state.currentLocationPage * LOCATIONS_PER_PAGE, this.state.currentLocationPage * LOCATIONS_PER_PAGE + LOCATIONS_PER_PAGE).map((l, idx) => {
      const gpsCoords = l.gpsCoords !== undefined && l.gpsCoords !== null && l.gpsCoords.indexOf("|") >= 0 ? l.gpsCoords : "00.000000|000.000000";
      const gpsSplit = gpsCoords.split('|');
      const lat = gpsSplit[0] !== undefined && gpsSplit[0] !== null && gpsSplit[0] !== "" ? gpsSplit[0] : "00.000000";
      const lon = gpsSplit[1] !== undefined && gpsSplit[1] !== null && gpsSplit[1] !== "" ? gpsSplit[1] : "000.000000";

      return (
        <Table.Row onKeyPress={this.manageEnterKey.bind(this)} key={idx} onClick={this.onClickOnLocationRow(l).bind(this)}>
          <Table.Cell textAlign='center' width={2}>
            {
            this.state.editingLocation.id === l.id ?
            <Input fluid placeholder='Nom...' value={this.state.editingLocation.name} onChange={this.onChangeLocationProp('name').bind(this)} />
            : l.name
            }
          </Table.Cell>
          <Table.Cell>
            {
              <Button data-tooltip="Cliquez pour modifier l'adresse" data-position="top left" icon color='blue' disabled={this.state.editingLocation.id !== l.id} onClick={this.toggleLocationSelectModal(l).bind(this)}>
                <Icon name='map' />
              </Button>
            }
            &nbsp;&nbsp;&nbsp;
            {
              this.state.editingLocation.id === l.id ?
                this.state.editingLocation.tmpAddr !== undefined ?
                  this.state.editingLocation.tmpAddr === ' ' ? 
                  <Button onClick={this.toggleLocationSelectModal(l).bind(this)}>Cliquez ici pour définir l'adresse</Button>
                  : ' ' + this.state.editingLocation.tmpAddr
                : <Loader active inline />
              :
            this.state.addrForLocations[l.id] !== undefined ?
              this.state.addrForLocations[l.id] === ' ' ? 
                <Button disabled onClick={this.toggleLocationSelectModal(l).bind(this)}>Cliquez ici pour définir l'adresse</Button>
                : ' ' + this.state.addrForLocations[l.id]
              : <Loader active inline />
            }
          </Table.Cell>
          <Table.Cell textAlign='center'>
            {
            this.state.editingLocation.id === l.id ?
            <Input fluid placeholder='Ex : 100' value={this.state.editingLocation.radius} onChange={this.onChangeLocationProp('radius').bind(this)} />
            : l.radius + 'm'
            }
          </Table.Cell>
          <Table.Cell textAlign='center'>{this.state.editingLocation.id === l.id ? editingLat : lat}</Table.Cell>
          <Table.Cell textAlign='center'>{this.state.editingLocation.id === l.id ? editingLon : lon}</Table.Cell>
          <Table.Cell singleLine={true} width={1} textAlign='center'>
            <Button data-tooltip="Enregistrer le site" data-position="bottom right" icon color='green' disabled={this.state.editingLocation.id !== l.id} onClick={this.onClickOnUpdLocation().bind(this)}>
              <Icon name='check' />
            </Button>

            <Modal trigger={
              <Button onClick={this.toggleDeleteLocationModal.bind(this)} data-tooltip="Supprimer le site" data-position="bottom right" icon color='red'>
                <Icon name='trash' />
              </Button>
            } basic size='small' open={this.state.deleteLocationModal} onOpen={this.onDeleteLocationModalOpen(l.id).bind(this)}>
              <Header icon='trash' content="Suppression d'un site géographique" />
              <Modal.Content>
                <p>
                  Attention, la suppression d'un site géographique réassignera les pointages du site
                  vers la zone "Inconnu". Êtes-vous certain de vouloir effectuer cette action ?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color='red' inverted onClick={this.toggleDeleteLocationModal.bind(this)}>
                  <Icon name='remove' /> Non, Annuler
                </Button>
                <Button color='green' inverted onClick={this.onClickOnDelLocation.bind(this)}>
                  <Icon name='checkmark' /> Oui, Confirmer
                </Button>
              </Modal.Actions>
            </Modal>
          </Table.Cell>
        </Table.Row>
      );
    });
    const errorMessage = (this.state.error !== "" ?
      <Message negative>
        <Message.Header>Une erreur est survenue</Message.Header>
        <p>{JSON.stringify(this.state.error)}</p>
      </Message>
      :
      <div />
    );
    const dropdownMapOpts = this.state.foundAddr.map((a,idx) => ({
      key: `${a.lat}|${a.lon}_${idx}`,
      text: a.fulladdr,
      value: `${a.lat}|${a.lon}`
    }));
    const mapModal = (
      <Modal
        onClose={this.toggleLocationSelectModal().bind(this)}
        onOpen={this.toggleLocationSelectModal().bind(this)}
        open={this.state.isLocationSelectModalOpened}
      >
        <Modal.Header>Emplacement du site&nbsp;&nbsp;{this.state.editingLocation.name}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Input disabled label='Latitude' value={this.state.selectedLatLng[0] || ''} />
            <Input disabled label='Longitude' value={this.state.selectedLatLng[1] || ''} />
            <br /><br />
            <h4>Renseignez l'adresse de votre site puis cliquez sur la carte pour ajuster son emplacement.</h4>
            <Dropdown
              style={{zIndex: '100 !important'}}
              placeholder='Rechercher une adresse...'
              fluid
              search={(data, queryStr) => data}
              selection
              options={dropdownMapOpts}
              loading={this.state.searchingAddrs}
              onChange={this.onChangeAddr.bind(this)}
              onSearchChange={this.onChangeSearchAddrModal.bind(this)}
            />
            <br />
            <LeafletMap onClick={this.onClickOnModalMap.bind(this)} id="modalMap" style={{height: '400px'}} center={this.state.modalMap_latLon} zoom={this.state.modalMap_zoom}>
                      <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {
                        this.state.selectedLatLng.length === 2 ?
                          <Marker position={[this.state.selectedLatLng[0], this.state.selectedLatLng[1]]} />
                        : ''
                      }
            </LeafletMap>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
              <Button onClick={this.onClickOnModalCancel.bind(this)} color='red'>Annuler</Button>
              <Button disabled={this.state.selectedLatLng.length !== 2} onClick={this.onClickOnModalValidate.bind(this)} color='green'>Valider</Button>
        </Modal.Actions>
      </Modal>
    );

    const sortedEmployeesToDisplay = this.sortEmployees();
    const gpsCoordsHTML = sortedEmployeesToDisplay.slice(this.state.currentPhonePage * POINTAGES_PER_PAGE, this.state.currentPhonePage * POINTAGES_PER_PAGE + POINTAGES_PER_PAGE).map((p, idxP) => {
      return <Table.Row key={`gpsCoords_${idxP}`}>
        <Table.Cell textAlign='center'>{p.employee.name}</Table.Cell>
        <Table.Cell textAlign='center'>{moment.unix(p.timestamp).format("DD/MM/YYYY à HH:mm")}</Table.Cell>
        <Table.Cell textAlign='center'>{p.location !== "" && p.location !== null && p.location !== undefined ? p.location : 'Inconnu'}</Table.Cell>
        <Table.Cell>
          {
            this.state.addrForPointages[p.id] !== undefined ?
              <Button style={{color: getContrast(getEmployeeColor(p.employee.id), '#000000') < 4.5 ? 'white' : 'black', backgroundColor: getEmployeeColor(p.employee.id)}} data-tooltip="Afficher le pointage sur la carte" data-position="top right" icon onClick={this.handleGoTo(p.gpsCoords.split('|')).bind(this)}>
                {this.state.addrForPointages[p.id]}
              </Button>
            : <Loader active inline='centered' />
          }
        </Table.Cell>
      </Table.Row>;
    });

    const filterEmployeeOpts = this.state.employees
    .filter(e => this.state.selectedTeamFilter !== "ALL" ? e.teamId === this.state.selectedTeamId : true)
    .map((e, idx) => ({
        key: e.id,
        text: e.name,
        value: e.id
    }));
    const filterTeamOpts = this.state.teams.map((t, idx) => ({
      key: t.id,
      text: t.name,
      value: t.id
    }));
    const getMapMarkers = this.state.summary.map((e, idx) => {
      return e.pointages.filter(p => p.gpsCoords !== null && p.gpsCoords !== undefined).map((p, idxP) => {
        return (
          <Marker icon={getMarkerIconForEmployee(e.id)} id={`marker_${idx}_${p.id}_${idxP}`} key={`marker_${idx}_${p.id}_${idxP}`} position={[p.gpsCoords.split('|')[0], p.gpsCoords.split('|')[1]]}>
            {
              <Tooltip>
                {e.name}<br />
                {moment.unix(p.timestamp).format("DD/MM/YYYY à HH:mm")}
              </Tooltip>
            }
          </Marker>
        );
      });
    }).flat();
    const getMapRoutes = this.state.mapRoutes.map((eRoute) => {
      return [
        eRoute.employeeName,
        eRoute.color,
        eRoute.paths.length > 0 ? eRoute.paths[0].points.coordinates.map(([lon,lat]) => [lat,lon]) : []
      ];
    }).map(([eName, color, coords], idx) => {
      return <Polyline key={`route_${idx}`} color={color} positions={coords}><Tooltip sticky={true}>{eName}</Tooltip></Polyline>
    });
    const getSiteMarkers = this.state.locations.filter(l => l.gpsCoords !== undefined && l.gpsCoords !== null && l.gpsCoords.trim() !== '')
      .map((l, idx) => {
        return (
          <Marker key={`marker_location_${idx}`} position={[l.gpsCoords.split('|')[0], l.gpsCoords.split('|')[1]]}>
            <Tooltip>
              {l.name}
            </Tooltip>
          </Marker>
        );
      });
    const getSiteLayer = this.state.locations.filter(l => l.gpsCoords !== undefined && l.gpsCoords !== null && l.gpsCoords.trim() !== '')
      .map(({name, gpsCoords, radius}, idx) => {
        return (
          <FeatureGroup key={`featuregroup_${idx}`} color="blue">
            <Tooltip>
              {name}
            </Tooltip>
            <Circle center={gpsCoords.split('|')} radius={radius} />
          </FeatureGroup>
        );
      });

    const filterCarOpts = this.state.cars.map(c => {
      const carEmployeeEntity = this.state.carEmployees.find(x => x.carImei === c.imei);
      return ({
        key: c.imei,
        value: c.id,
        text: `${c.name}${carEmployeeEntity !== undefined ? ` - ${carEmployeeEntity.licensePlate}` : ''}`
      })
    });

    const sortedSummary = this.sortTraccarSummary();
    const carsHtml = sortedSummary.slice(this.state.currentCarPage * TRIPS_PER_PAGE, this.state.currentCarPage * TRIPS_PER_PAGE + TRIPS_PER_PAGE).map((trip, idx) => {
      const addrIdx = this.getTripId(trip);
      let durationH = moment.duration(trip.duration).get('hours').toString();
      durationH = durationH.length < 2 ? '0' + durationH : durationH;
      let durationM = moment.duration(trip.duration).get('minutes').toString();
      durationM = durationM.length < 2 ? '0' + durationM : durationM;
      const tripCar = this.state.cars.find(x => x.id === trip.deviceId);
      const carEmployee = tripCar !== undefined ? this.state.carEmployees.find(x => x.carImei === tripCar.imei) : undefined;
      const employee = carEmployee !== undefined ? this.state.employees.find(x => x.id === carEmployee.employeeId) : undefined;
      return <Table.Row key={`car_trip_${idx}`}>
          <Table.Cell textAlign='center'>{`${trip.deviceName} / ${carEmployee !== undefined ? ` ${carEmployee.licensePlate || ""}` : ''}`}</Table.Cell>
          <Table.Cell textAlign='center'>{employee === undefined ? 'Non assigné' : employee.name}</Table.Cell>
          <Table.Cell textAlign='center'>{moment(trip.startTime).format("DD/MM/YYYY à HH:mm")}</Table.Cell>
          <Table.Cell>
            {
            this.state.addrForTrips[addrIdx] !== undefined ?
              <Button style={{color: getContrast(getEmployeeColor(trip.deviceName), '#000000') < 4.5 ? 'white' : 'black', backgroundColor: getEmployeeColor(trip.deviceName)}} data-tooltip="Afficher sur la carte" data-position="top right" icon onClick={this.handleGoToCar([trip.startLat, trip.startLon]).bind(this)}>
                {this.state.addrForTrips[addrIdx].start}
              </Button>
              : <Loader active inline='centered' />
            }
          </Table.Cell>
          <Table.Cell textAlign='center'>{moment(trip.endTime).format("DD/MM/YYYY à HH:mm")}</Table.Cell>
          <Table.Cell>
            {
            this.state.addrForTrips[addrIdx] !== undefined ?
              <Button style={{color: getContrast(getEmployeeColor(trip.deviceName), '#000000') < 4.5 ? 'white' : 'black', backgroundColor: getEmployeeColor(trip.deviceName)}} data-tooltip="Afficher sur la carte" data-position="top right" icon onClick={this.handleGoToCar([trip.endLat, trip.endLon]).bind(this)}>
                {this.state.addrForTrips[addrIdx].end}
              </Button>
              : <Loader active inline='centered' />
            }
          </Table.Cell>
          <Table.Cell textAlign='center'>{durationH !== "00" ? durationH + " h et " : "" } {durationM} min</Table.Cell>
          <Table.Cell textAlign='center'>{Math.round(trip.averageSpeed)} km/h</Table.Cell>
      </Table.Row>;
    });

    const getCarMarkers = this.state.traccarSummary.map((trip, idx) => {
      const carEmployeeEntity = this.state.carEmployees.find(x => x.carImei === trip.deviceImei) || {};
      const employee = this.state.employees.find(x => x.id === carEmployeeEntity.employeeId) || {};
      return [
        <Marker icon={getMarkerIconForEmployee(trip.deviceName)} key={`car_marker_start_${idx}`} position={[trip.startLat, trip.startLon]}>
          {
            <Tooltip>
              {trip.deviceName}<br />
              {employee.name}<br />
              {moment(trip.startTime).format("DD/MM/YYYY HH:mm")}
            </Tooltip>
          }
        </Marker>,
        <Marker icon={getMarkerIconForEmployee(trip.deviceName)} key={`car_marker_end_${idx}`} position={[trip.endLat, trip.endLon]}>
        {
          <Tooltip>
              {trip.deviceName}<br />
              {employee.name}<br />
              {moment(trip.endTime).format("DD/MM/YYYY HH:mm")}
          </Tooltip>
        }
        </Marker>
      ];
    }).flat();

    const carsPointsGrouped = groupBy(this.state.carsPoints, 'deviceId');

    /*const bearing = (start, end) => {
      //const radians = (n) => n*(Math.PI / 180.0);
      const degrees = (n) => n*(180.0 / Math.PI);
      const [startLat, startLon] = start;
      const [endLat, endLon] = end;
      var dLon = endLon - startLon;
      var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));
      if (Math.abs(dLon) > Math.PI){
        if (dLon > 0.0)
            dLon = -(2.0 * Math.PI - dLon);
        else
            dLon = (2.0 * Math.PI + dLon);
      }
      return (degrees(Math.atan2(dLon, dPhi)) + 360.0) % 360.0;
    }

    const getCarSvg = (coords, nextCoords, color) => {
      return '<svg style="transform:rotate('+bearing(coords, nextCoords)+'deg);" xmlns="http://www.w3.org/2000/svg" fill="'+color+'" version="1.1" width="100" height="100"><path d="M 50,30 70,70 30,70 z"></path></svg>';
    }*/

    /*const getCarMarkers = Object.keys(carsPointsGrouped).map(dId => {
      const car = this.state.cars.find(c => c.id === parseInt(dId));
      if(car !== undefined) {
        return carsPointsGrouped[dId].map((p, idx) =>
          ({
            id: `car_marker_${car.id}_${idx}_${p.id}`,
            iconColor: getEmployeeColor(car.name),
            position: p.position,
            tooltip: `${car.name} (${moment(p.fixTime).format("DD/MM/YYYY HH:mm")})`,
            iconId: `car_marker_${car.id}_${idx}_${p.id}`,
            markerSpriteAnchor: [0.5, 0.5],
            customIcon: getCarSvg(p.position, carsPointsGrouped[dId][idx+1] ? carsPointsGrouped[dId][idx+1].position : p.position, getEmployeeColor(car.name)),
          })
      );
      } else {
        return null;
      }
    }).filter(r => r !== null).flat();*/

    /*const getCarRoutes = this.state.traccarSummary.map((trip) => {
      return [
        trip.deviceName,
        getEmployeeColor(trip.deviceName),
        trip.route.paths.length > 0 ? trip.route.paths[0].points.coordinates.map(([lon,lat]) => [lat,lon]) : []
      ];
    }).map(([deviceName, color, coords], idx) => {
      return <Polyline key={`car_route_${idx}`} color={color} positions={coords}><Tooltip sticky={true}>{deviceName}</Tooltip></Polyline>
    });*/

    
    const getCarRoutes = Object.keys(carsPointsGrouped).map(dId => {
      const car = this.state.cars.find(c => c.id === parseInt(dId));
      const carEmployeeEntity = this.state.carEmployees.find(x => x.carImei === car.imei) || {};
      const employee = this.state.employees.find(x => x.id === carEmployeeEntity.employeeId) || {};
      if(car !== undefined) {
        return <Polyline key={`car_route_${dId}`} color={getEmployeeColor(car.name)} positions={carsPointsGrouped[dId].map(p => p.position)}>
          <Tooltip sticky={true}>
            {employee.name || '"Conducteur Inconnu"'}<br />{carEmployeeEntity.licensePlate || `${car.name}`}
          </Tooltip>
        </Polyline>;
      } else {
        return null;
      }
    }).filter(r => r !== null);

    return (
        <Grid padded stackable>
          {
            isSubmoduleVisible('movings', 'phones') ? [
          <Grid.Row className="firstRow" key='rowOne'>
              <Header dividing size="huge" as="h1">
              <Icon name='street view' />
              Déplacements des employés
              </Header>
          </Grid.Row>,
          <Grid.Row key='rowThree'>
            <Grid.Column width={16}>
              <Label size="big" style={{backgroundColor: 'transparent'}}>Période actuellement affichée :</Label>
              <Label id="currentPeriodFilter" color="blue" size="big">
                {
                  this.state.selectedDateFilter.from !== undefined ?
                    this.state.selectedDateFilter.from.format("DD/MM/YYYY")
                    : ""
                }
                {
                  this.state.selectedDateFilter.to !== undefined ?
                    " - " + this.state.selectedDateFilter.to.format("DD/MM/YYYY")
                    : ""
                }
              </Label>
            </Grid.Column>
          </Grid.Row>,
          <Grid.Row key='rowTwo'>
            {errorMessage}
            <Grid.Column width={3} id="gps_employeeFilter">
              {
                this.props.isAdmin() === true ?
                  <Dropdown
                    placeholder='Par équipe'
                    search
                    fluid
                    selection
                    style={{zIndex: 100}}
                    onChange={this.onChangeTeamFilter.bind(this)}
                    value={this.state.selectedTeamFilter}
                    options={[
                      {
                        key: "ALL",
                        text: "Toutes les équipes",
                        value: "ALL"
                      },
                      ...filterTeamOpts
                    ]}
                  />
                  : ""
              }
              <Dropdown
                placeholder='Tous les employés'
                search
                fluid
                multiple
                selection
                clearable
                onChange={this.onChangeEmployeeFilter.bind(this)}
                value={this.state.selectedEmployeeFilter}
                style={{zIndex: 99}}
                options={filterEmployeeOpts}
              />
            </Grid.Column>
            <Grid.Column width={3} id="gps_periodFilter">
            <DatesRangeInput
                name="dateFilter"
                autoComplete="off"
                localization="fr"
                dateFormat="DD/MM/YYYY"
                placeholder="Par période"
                closeOnMouseLeave={false}
                onClear={this.initDateFilter.bind(this)}
                value={this.state.selectedDateFilter.readable}
                closable
                clearable
                fluid
                clearIcon="trash"
                allowSameEndDate
                iconPosition="right"
                hideMobileKeyboard
                onChange={this.onChangeDateFilter.bind(this)}
                animation='none'
              />
            </Grid.Column>
            <Grid.Column width={2}><Button color='blue' size="large" icon labelPosition='right' compact onClick={this.refreshSummary.bind(this)}>Recherche<Icon name='search' /></Button></Grid.Column>
            <Grid.Column width={5}></Grid.Column>
          </Grid.Row>,
          <Grid.Row key="rowAccordion">
            <Grid.Column width={16} verticalAlign='top'>
              <Icon name={`arrow circle ${this.state.accordionLocationsOpen === true ? 'down' : 'right'}`}/>
              <span id='accordionDepInMovingRow' onClick={this.handleClickLocationAccordion.bind(this)}>
                {`${this.state.accordionLocationsOpen === true ? 'Masquer' : 'Afficher'} la liste des sites externes (${this.state.locations.length})`}
              </span>
              {
                this.state.accordionLocationsOpen ?
                <Segment raised>
                  <Divider hidden />
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header dividing as='h2'>
                          <Icon name='location arrow'/>
                          <Header.Content>
                            Gestion des sites externes
                            <Button onClick={this.onClickOnAddLocation.bind(this)} compact icon='plus' floated='right' color='green' content="Ajouter" size="tiny" style={{marginLeft: '10px'}} />
                            <Header.Subheader>{this.state.locations.length} Site(s) enregistré(s)</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                      {
                        this.state.locations.length > LOCATIONS_PER_PAGE ?
                        <Grid.Row key="rowLocationPagesTop">
                          <Grid.Column width={14}>
                            <Pagination 
                              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                              prevItem={{ content: <Icon name='angle left' />, icon: true }}
                              nextItem={{ content: <Icon name='angle right' />, icon: true }}
                              activePage={this.state.currentLocationPage+1} totalPages={Math.ceil(this.state.locations.length / LOCATIONS_PER_PAGE)} onPageChange={this.onLocationPageChange.bind(this)} />
                          </Grid.Column>
                        </Grid.Row> : ''
                      }
                    <Grid.Row key="rowLocationTable">
                      <Table striped selectable sortable>
                        <Table.Header>
                          <Table.Row textAlign='center'>
                            <Table.HeaderCell>
                              Nom du site
                            </Table.HeaderCell>
                            <Table.HeaderCell>Adresse du site</Table.HeaderCell>
                            <Table.HeaderCell><div data-tooltip="Définissez la taille du cercle couvrant l'emplacement de votre site. (en mètres)&#10;Les pointages réalisés à l'intérieur du cercle seront automatiquement reliés au site correspondant." data-position="top right">Rayon d'action <Icon name='info circle' /></div></Table.HeaderCell>
                            <Table.HeaderCell>Latitude</Table.HeaderCell>
                            <Table.HeaderCell>Longitude</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {locationsHTML}
                        </Table.Body>
                      </Table>
                    </Grid.Row>
                      {
                        this.state.locations.length > LOCATIONS_PER_PAGE ?
                        <Grid.Row key="rowLocationPagesBottom">
                          <Grid.Column width={14}>
                            <Pagination 
                              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                              prevItem={{ content: <Icon name='angle left' />, icon: true }}
                              nextItem={{ content: <Icon name='angle right' />, icon: true }}
                              activePage={this.state.currentLocationPage+1} totalPages={Math.ceil(this.state.locations.length / LOCATIONS_PER_PAGE)} onPageChange={this.onLocationPageChange.bind(this)} />
                          </Grid.Column>
                        </Grid.Row> : ''
                      }
                    <Grid.Row>
                      <Grid.Column width={16} textAlign="center">
                        <Icon 
                          name='chevron circle up'
                          onClick={this.handleClickLocationAccordion.bind(this)}
                          size="big"
                          link
                          title="Masquer la liste"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment> : ''
              }
            </Grid.Column>
          </Grid.Row>,
              (
              sortedEmployeesToDisplay.length > POINTAGES_PER_PAGE ?
              <Grid.Row key="rowPhonePagesTop">
                <Grid.Column width={14}>
                  <Pagination
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    activePage={this.state.currentPhonePage+1} totalPages={Math.ceil(sortedEmployeesToDisplay.map(e => e.pointages).flat().length / POINTAGES_PER_PAGE)} onPageChange={this.onPhonePageChange.bind(this)} />
                </Grid.Column>
              </Grid.Row>
              : <Grid.Row key="rowPhonePagesTop" />
              ),
            <Grid.Row key='rowFour'>
              <Table singleLine striped selectable sortable>
                <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell
                        sorted={this.state.sortState.employees.column === 'employeeName' ? this.state.sortState.employees.direction : null}
                        onClick={this.handleSort('employees', 'employeeName').bind(this)}>
                        Employé
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={this.state.sortState.employees.column === 'timestamp' ? this.state.sortState.employees.direction : null}
                        onClick={this.handleSort('employees', 'timestamp').bind(this)}>
                        Date et Heure
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={this.state.sortState.employees.column === 'location' ? this.state.sortState.employees.direction : null}
                        onClick={this.handleSort('employees', 'location').bind(this)}>
                        Site détecté
                      </Table.HeaderCell>
                      <Table.HeaderCell>Adresse enregistrée lors du pointage</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Dimmer.Dimmable key="dimmer_phones" as={Table.Body} style={{minHeight: '100px', zIndex: 0}} dimmed={this.state.loadingPhones}>
                      {gpsCoordsHTML}
                      <Table.Row>
                        <Table.Cell>
                          <Dimmer inverted verticalAlign='top' active={this.state.loadingPhones}>
                            <Loader inline>Chargement</Loader>
                          </Dimmer>
                        </Table.Cell>
                      </Table.Row>
                  </Dimmer.Dimmable>
              </Table>
            </Grid.Row>,
              (
                sortedEmployeesToDisplay.length > POINTAGES_PER_PAGE ?
                <Grid.Row key="rowPhonePagesBottom">
                  <Grid.Column width={14}>
                    <Pagination
                      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                      firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                      lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                      prevItem={{ content: <Icon name='angle left' />, icon: true }}
                      nextItem={{ content: <Icon name='angle right' />, icon: true }}
                      activePage={this.state.currentPhonePage+1} totalPages={Math.ceil(sortedEmployeesToDisplay.map(e => e.pointages).flat().length / POINTAGES_PER_PAGE)} onPageChange={this.onPhonePageChange.bind(this)} />
                  </Grid.Column>
                </Grid.Row>
                : <Grid.Row key="rowPhonePagesBottom"/>
              ),
              <Grid.Row key='rowFive' style={{display: 'block'}}>
                <Container style={{width: '100%'}}>
                  <LeafletMap scrollWheelZoom={this.state.phoneMapHasFocus} onfocus={() => this.setState({phoneMapHasFocus: true})} onblur={() => this.setState({phoneMapHasFocus: false})} zIndex={50} style={{height: '700px', width: '90%'}} center={this.state.map_latLon} zoom={this.state.map_zoom}>
                    <TileLayer
                      zIndex={50}
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {getMapMarkers}
                    {getSiteMarkers}
                    {getMapRoutes}
                    {getSiteLayer}
                  </LeafletMap>
              </Container>
            </Grid.Row>,
            <Divider hidden key='rowSix' />,
            <div key="dividerRowMovingsBr"><br /><br /><br /><br /></div>,
          ] : ''
          }
          {
            isSubmoduleVisible('movings', 'cars') ? [
          <Grid.Row className="firstRow" key='rowOne'>
              <Header dividing size="huge" as="h1">
              <Icon name='shipping' />
              Déplacements des véhicules
              </Header>
          </Grid.Row>,
          <Grid.Row key='rowThree'>
          <Grid.Column width={16}>
            <Label size="big" style={{backgroundColor: 'transparent'}}>Période actuellement affichée :</Label>
            <Label id="car_currentPeriodFilter" color="blue" size="big">
              {
                this.state.carFilters.selectedDateFilter.from !== undefined ? 
                  this.state.carFilters.selectedDateFilter.from.format("DD/MM/YYYY")
                  : ""
              }
              {
                this.state.carFilters.selectedDateFilter.to !== undefined ?
                  " - " + this.state.carFilters.selectedDateFilter.to.format("DD/MM/YYYY")
                  : ""
              }
            </Label>
          </Grid.Column>
         </Grid.Row>,
          <Grid.Row key='rowTwo'>
            <Grid.Column width={3} id="car_filter">
              <Dropdown
                placeholder='Par véhicule'
                search
                fluid
                selection
                onChange={this.onChangeCarFilter.bind(this)}
                value={this.state.carFilters.selectedCarFilter}
                style={{zIndex: 100}}
                options={[
                  {
                    key: "ALL",
                    text: "Tous les véhicules",
                    value: "ALL"
                  },
                  ...filterCarOpts
                ]}
              />
            </Grid.Column>
            <Grid.Column width={3} id="car_periodFilter">
            <DatesRangeInput
                name="dateFilter"
                autoComplete="off"
                localization="fr"
                dateFormat="DD/MM/YYYY"
                placeholder="Par période"
                closeOnMouseLeave={false}
                onClear={this.initCarDateFilter.bind(this)}
                value={this.state.carFilters.selectedDateFilter.readable}
                closable
                clearable
                fluid
                clearIcon="trash"
                allowSameEndDate
                iconPosition="right"
                hideMobileKeyboard
                onChange={this.onChangeCarDateFilter.bind(this)}
                animation='none'
              />
            </Grid.Column>
            <Grid.Column width={2}><Button color='blue' size="large" icon labelPosition='right' compact onClick={this.refreshTraccarSummary.bind(this)}>Recherche<Icon name='search' /></Button></Grid.Column>
            <Grid.Column width={5}></Grid.Column>
          </Grid.Row>,
          (
            this.state.traccarSummary.length > TRIPS_PER_PAGE ?
            <Grid.Row key="rowCarPagesTop">
              <Grid.Column width={14}>
                <Pagination 
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                  lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                  activePage={this.state.currentCarPage+1} totalPages={Math.ceil(this.state.traccarSummary.length / TRIPS_PER_PAGE)} onPageChange={this.onTripPageChange.bind(this)} />
              </Grid.Column>
            </Grid.Row> : <Grid.Row key="rowCarPagesTop" />
          ),
              <Grid.Row key='rowFour'>
              <Table striped selectable sortable>
                <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell
                        sorted={this.state.sortState.cars.column === 'vehicleName' ? this.state.sortState.cars.direction : null}
                        onClick={this.handleSort('cars', 'vehicleName').bind(this)}>
                        Véhicule
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={this.state.sortState.cars.column === 'employee' ? this.state.sortState.cars.direction : null}
                        onClick={this.handleSort('cars', 'employee').bind(this)}>
                        Employé
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={this.state.sortState.cars.column === 'startTime' ? this.state.sortState.cars.direction : null}
                        onClick={this.handleSort('cars', 'startTime').bind(this)}>
                        Date de départ
                      </Table.HeaderCell>
                      <Table.HeaderCell>Adresse de départ</Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={this.state.sortState.cars.column === 'endTime' ? this.state.sortState.cars.direction : null}
                        onClick={this.handleSort('cars', 'endTime').bind(this)}>
                        Date d'arrivée
                      </Table.HeaderCell>
                      <Table.HeaderCell>Adresse d'arrivée</Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={this.state.sortState.cars.column === 'duration' ? this.state.sortState.cars.direction : null}
                        onClick={this.handleSort('cars', 'duration').bind(this)}>
                        Durée du trajet
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={this.state.sortState.cars.column === 'avgSpeed' ? this.state.sortState.cars.direction : null}
                        onClick={this.handleSort('cars', 'avgSpeed').bind(this)}>
                          Vitesse moyenne
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Dimmer.Dimmable key="dimmer_cars" as={Table.Body} style={{minHeight: '100px', zIndex: 0}} dimmed={this.state.loadingCars}>
                      {carsHtml}
                      <Table.Row>
                        <Table.Cell>
                          <Dimmer inverted verticalAlign='top' active={this.state.loadingCars}>
                            <Loader inline>Chargement</Loader>
                          </Dimmer>
                        </Table.Cell>
                      </Table.Row>
                  </Dimmer.Dimmable>
                </Table>
              </Grid.Row>,
              (
                this.state.traccarSummary.length > TRIPS_PER_PAGE ?
                <Grid.Row key="rowCarPagesBottom">
                  <Grid.Column width={14}>
                    <Pagination 
                      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                      firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                      lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                      prevItem={{ content: <Icon name='angle left' />, icon: true }}
                      nextItem={{ content: <Icon name='angle right' />, icon: true }}
                      activePage={this.state.currentCarPage+1} totalPages={Math.ceil(this.state.traccarSummary.length / TRIPS_PER_PAGE)} onPageChange={this.onTripPageChange.bind(this)} />
                  </Grid.Column>
                </Grid.Row> : <Grid.Row key="rowCarPagesBottom" />
              ),
              <Grid.Row key='rowSix' style={{display: 'block'}}>
                <Container style={{width: '100%'}}>
                  <LeafletMap scrollWheelZoom={this.state.carMapHasFocus} onfocus={() => this.setState({carMapHasFocus: true})} onblur={() => this.setState({carMapHasFocus: false})} minZoom={3} maxZoom={19} zIndex={50} style={{height: '700px', width: '90%'}} center={this.state.car_map_latLon} zoom={this.state.car_map_zoom}>
                    <TileLayer
                      zIndex={50}
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <PixiOverlay shouldRedrawOnMove={(e) => e.flyTo || e.pinch} markers={getCarMarkers} />
                    {getCarRoutes}
                  </LeafletMap>
              </Container>
            </Grid.Row>,
            <Divider hidden key="dividerRowLocations" />,
            <div key="dividerRowLocationsBr"><br /><br /><br /></div>,
          ] : ''
        }
        <Grid.Row key="mapModalLocations">{mapModal}</Grid.Row>
      </Grid>
    );
  }
}

export default Movings;
